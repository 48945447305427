import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import mainLogo from "../dist/img/smartwayMainlogo/Smartway_new.png"
import { useForm } from "react-hook-form";
import { loginCallFrontHandler, loginCallHandler } from "../api";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const Login = () => {
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const {register, handleSubmit, formState: { errors }, setValue} = useForm()
    const navigate = useNavigate()
    const isLoginUser = JSON.parse(localStorage.getItem("token_details"))
    const isLoginUserFront = JSON.parse(localStorage.getItem("token_details_F"))

    useEffect(() => {
        document.getElementById("htmlContent").className = "hold-transition"
        document.getElementById("htmlContent").className = "login-page"
        return () => {
            document.getElementById("htmlContent").className = "hold-transition"
            document.getElementById("htmlContent").className = "login-page"
        };
    }, []);

    useEffect(() => {
        if (isLoginUser && !!location.pathname.includes("/video-admin")) {
            navigate("/video-admin/video-management")
        } else if (isLoginUserFront && !location.pathname.includes("/video-admin")) {
            navigate("/dashboard")
        }
    }, [])

    const onUserLoginHandler = useCallback(async(value) => {
        setLoading(true)
        const formData = new FormData()
        formData.append("name", value.name)
        formData.append("password", value.password)
        if (!!location.pathname.includes("/video-admin")) {
            try {
                const loginResponse = await loginCallHandler(formData)
                if (loginResponse?.status === 200) {
                    const details = {
                        token:loginResponse?.data?.token,
                        log:loginResponse?.data?.admin
                    }
                    localStorage.setItem("token_details", JSON.stringify(details))
                    toast.success("Login successfully")
                    navigate("/video-admin/video-management")
                    setLoading(false)
                }
            } catch (error) {
                if (error?.response?.status === 401) {
                    navigate("/video-admin")
                    setLoading(false)
                }
            } finally {
                setLoading(false)
            }
        } else {
                try {
                    const loginResponse = await loginCallFrontHandler(formData)

                    if (loginResponse?.status === 200) {
                        const details = {
                            token:loginResponse?.data?.token,
                            log:loginResponse?.data?.user
                        }
                        localStorage.setItem("token_details_F", JSON.stringify(details))
                        toast.success("Login successfully")
                        navigate("/dashboard")
                        setLoading(false)
                    }
                } catch (error) {
                    if (error?.response?.status === 401) {
                        navigate("/")
                        setLoading(false)
                    }
                } finally {
                    setLoading(false)
                }
            }
    }, [navigate, location])

    const onValidatePassword = (value) => {
        if (value.length < 8) {
            return 'Please enter 8 digits password.';
        }
        return true;
    }

    const onFieldChangeHandler = (value, name) => {
        setValue(name, value.target.value.replace(/^\s*$/, ''))
    }

    return (
        <div className="login-box container-fluid">
                <div className="login-logo">
                    <div className="brand-logo text-center">
                    <a href="#!"><img src={mainLogo} alt="logo" className="img-fluid" /></a>
                    </div>
                    <strong className="title">{`Video Portal`}</strong>
                </div>
            <div className="card">
                <div className="card-body login-card-body">
                {/* <p className="login-box-msg">Sign in to start your session</p> */}

                <form onSubmit={handleSubmit(onUserLoginHandler)}>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" id="name" {...register('name', {required:'Username is required', onChange:(e) => onFieldChangeHandler(e, "name")})}  placeholder="Username"/>
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                        {errors?.name && <p className="text-danger">{errors?.name?.message}</p>}
                    <div className="input-group mb-3">
                        <input type="password" className="form-control" id="password" {...register('password', {required:'Password is required', validate:onValidatePassword, onChange:(e) => onFieldChangeHandler(e, "password")})} placeholder="Password"/>
                        <div className="input-group-append">
                            <div className="input-group-text">
                            <span className="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                        {errors?.password && <p className="text-danger">{errors?.password?.message}</p>}
                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-info btn-block">{loading ? <Spinner/> : "Sign In"}</button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
            </div>
    )
}
export default Login