const Footer = () => {

    return (
        <footer className="main-footer">
            <strong>Copyright &copy; {`${new Date().getFullYear()}`} <a href="#!" className="text-gray-dark">Smartway Video Portal</a>.</strong>
            All rights reserved.
        </footer>
    )
}

export default Footer