import { useCallback, useEffect, useState } from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { userDetailsHandler } from "../../api"
import {getTokenFront } from "../../global"
import { useNavigate } from "react-router-dom"

const Dashboard = () => {
    const [videoDetails, setVideoDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    
    const onVideoDetails = useCallback(async() => {
        setLoading(true)
        const token = getTokenFront()
        try {
            const userDetailsResponse = await userDetailsHandler(token)
            
            if (userDetailsResponse?.status === 200) {
                setVideoDetails(userDetailsResponse?.data?.user)
                setLoading(false)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                navigate("/")
                setLoading(false)
            }
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        onVideoDetails()
    }, [])

    return (
        <div className="card">
            <div className="card-body">
                {loading ? <div className="text-center"><Spinner/></div> : <><Row className="py-2">
                    <Col lg="2"><label>Sender Name:</label></Col>
                    <Col lg="10"><span>{!videoDetails?.video_sender_name || videoDetails?.video_sender_name === "null" ? "" : videoDetails?.video_sender_name}</span></Col>
                </Row>
                <Row className="py-2">
                    <Col lg="2"><label>Title:</label></Col>
                    <Col lg="10"><span>{!videoDetails?.video_title || videoDetails?.video_title === "null" ? "" : videoDetails?.video_title}</span></Col>
                </Row>
                {videoDetails?.video_name && <Row className="py-2">
                    <Col lg="2"><label>Video:</label></Col>
                    <Col lg="10"><video loop autoPlay muted controls className="img-fluid rounded" src={videoDetails?.video_name ? `https://videoapp.smartwaypharma.com/static/upload/${videoDetails?.video_name}` : ""} /></Col>
                </Row>}
                <Row className="py-2">
                    <Col lg="2"><label>Description:</label></Col>
                    <Col lg="10"><span>{!videoDetails?.description || videoDetails?.description === "null" ? "" : videoDetails?.description}</span></Col>
                </Row></>}
            </div>
        </div>
    )
}

export default Dashboard