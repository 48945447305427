import './App.css';
import { ToastContainer } from 'react-toastify';

// css admin lte
import "../src/dist/css/adminlte.min.css.map"
import "../src/dist/css/adminlte.css.map"
import "../src/dist/css/adminlte.min.css"
import "../src/dist/css/responsive.css"
import "../src/dist/css/adminlte.css"

import "../src/plugins/fontawesome-free/css/all.min.css"
import 'react-toastify/dist/ReactToastify.css';

// component

import Login from './view/Login';
import { Route, BrowserRouter as Router, Routes, useLocation} from 'react-router-dom';
import MainLayout from './view/MainLayout';
import VideoManagement from './view/OutletComponent/VideoManagement';
import ChangePassword from './view/OutletComponent/ChangePassword';
import Dashboard from './view/OutletComponent/Dashboard';

function App() {
  
  return (
    <>
      <ToastContainer style={{top:"70px"}} hideProgressBar={true}/>
      <Router>
        <Routes>
          {/* front */}
              <Route path='/' element={<Login/>}/>
              <Route path='/dashboard' element={<MainLayout/>}>
                <Route path='/dashboard' element={<Dashboard/>}/>
              </Route>
             {/* admin */}
             <Route path='/video-admin' element={<Login/>}/>
              <Route path='/video-admin/video-management' element={<MainLayout/>}>
                <Route path='/video-admin/video-management' element={<VideoManagement/>}/>
              </Route>
              <Route path='/video-admin/change-password' element={<MainLayout/>}>
                <Route path='/video-admin/change-password' element={<ChangePassword/>}/>
              </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
