import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, FormLabel, InputGroup, Row, Spinner } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { changePasswordHandler } from "../../api";
import { getToken } from "../../global";
import { toast } from "react-toastify";


const ChangePassword = () => {
    const [isloading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const {register, handleSubmit, formState: { errors }, reset ,setValue, getValues} = useForm()
    
    
    const onValidateNewPassword = (value, name) => {
        if (name !== "confirmPassword" && value.length < 8) {
            return 'Please enter 8 digits password.';
        } else if (name === "confirmPassword" && getValues("newPassword") !== value) {
            return 'Confirm password and New password should be same'
        }
    }
    
    const onFieldChangeHandler = (value, name) => {
        setValue(name, value.target.value.replace(/^\s*$/, ''))
    }

    const onSubmitHandler = useCallback(async (data) => {
        setIsLoading(true)
        const changePasswordPayload = new FormData()
        changePasswordPayload.append("current_password", data?.currentPassword)
        changePasswordPayload.append("new_password", data?.newPassword)
        changePasswordPayload.append("confirm_password", data?.confirmPassword)
        const token = getToken()
        
        try {
            const changePasswordResponse = await changePasswordHandler(changePasswordPayload, token)
            
            if (changePasswordResponse?.status === 200) {
                toast.success("Password changed successfully.")
                setIsLoading(false)
                reset()
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                navigate("/video-admin")
                setIsLoading(false)
            }
        } finally {
            setIsLoading(false)
        }

    }, [])

    return (
        <div className="content inner-tbl change-password-wrapper pt-5">
        <div className="container">
            <Row className="row">
                <Col md={8} lg={5} className="col-12 m-auto">
                <Card>
                    <Card.Header className="main-title text-center">
                        <h2>Change Password</h2>
                    </Card.Header>

                    <Card.Body>
                        <form onSubmit={handleSubmit(onSubmitHandler)}>
                            <div className="form-group">
                                <label className="font-weight-normal">Current Password: <span className="text-danger">*</span></label>
                                <input type="password" {...register('currentPassword', {required:'Current password is required', onChange:(e) => onFieldChangeHandler(e, "currentPassword"), validate:(e) => onValidateNewPassword(e, "currentPassword")})} className="form-control"/>
                                {errors?.currentPassword && <p className="text-danger mt-1">{errors?.currentPassword?.message}</p>}
                            </div>
                            <div className="form-group">
                                <label className="font-weight-normal">New Password: <span className="text-danger">*</span></label>
                                <input type="password" {...register('newPassword', {required:'New password is required', onChange:(e) => onFieldChangeHandler(e, "newPassword"), validate:(e) => onValidateNewPassword(e, "newPassword")})} className="form-control"/>
                                {errors?.newPassword && <p className="text-danger mt-1">{errors?.newPassword?.message}</p>}
                            </div>
                            <div className="form-group">
                                <label className="font-weight-normal">Confirm Password: <span className="text-danger">*</span></label>
                                <input type="password" {...register('confirmPassword', {required:'Confirm password is required', onChange:(e) => onFieldChangeHandler(e, "confirmPassword"), validate:(e) => onValidateNewPassword(e, "confirmPassword")})}className="form-control"/>
                                {errors?.confirmPassword && <p className="text-danger mt-1">{errors?.confirmPassword?.message}</p>}
                            </div>
                            <button type="submit" className="btn btn-info btn-md w-100">{isloading ? <Spinner animation="border" role="status"/> : "Update"}</button>
                        </form>
                    </Card.Body>
                </Card>
                </Col>
            </Row>
        </div>
        </div>
    )
}

export default ChangePassword