import Datatable from "./Datatable"

const VideoManagement = () => {


    return (
        <div className="card">
                <Datatable/>
        </div>
    )
}

export default VideoManagement