import { useCallback, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"

import mainLogo from "../dist/img/smartwayMainlogo/Smartway_new.png"
import { getToken, getTokenFront } from "../global"
import { LogOutHandler, LogOutHandlerForFront } from "../api"
import { toast } from "react-toastify"
import Swal from "sweetalert2";

const Navigation = () => {
    const isUserLogin = JSON.parse(localStorage.getItem("token_details"))
    const isUserLoginFront = JSON.parse(localStorage.getItem("token_details_F"))
    const location = useLocation()
    const navigate = useNavigate()
    const [mobileNav, setMobileNav] = useState(false)
    const onMobileNavHandler = useCallback(() => {
        setMobileNav((prev) => {
          return !prev
        })
    }, [])
      
    const onSignOutHandler = useCallback(async() => {
      Swal.fire({
        text: `Are you sure you want to sign out?`,
        icon: '',
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        cancelButtonColor:"#17a2b8",
        confirmButtonColor:"#17a2b8",
        showCancelButton:true
      }).then(async(result) => {
          if (result.isConfirmed) {

            setMobileNav((prev) => {
              return !prev
            })
            const token = getToken()
            const tokenFront = getTokenFront()
            if (!!location.pathname.includes("/video-admin")) {
              try {
                const signOutResponse = await LogOutHandler(token)
                if (signOutResponse?.status === 200) {
                  localStorage.removeItem("token_details")
                  toast.success("Logout successfully")
                  navigate("/video-admin")
                }
              }  catch (error) {
                if (error?.response?.status === 401) {
                  navigate("/video-admin")
                }
              }
            } else {
              try {
                const signOutResponse = await LogOutHandlerForFront(tokenFront)
                if (signOutResponse?.status === 200) {
                  localStorage.removeItem("token_details_F")
                  toast.success("Logout successfully")
                  navigate("/")
                }
              }  catch (error) {
                if (error?.response?.status === 401) {
                    navigate("/")
                }
              }
            }
          }
      })
    }, [navigate])

    return (

        <nav className="main-header navbar bg-info sticky-top navbar-expand-md navbar-light navbar-white">
            <div className="container-fluid">
          <Link to="/video-admin/video-management" className="navbar-brand">
                {/* <div className="login-logo" style={{fontSize:"1.25rem", marginBottom:0}}>
                    <div className="brand-logo text-center">
                      <img src={mainLogo} alt="logo" className="img-fluid" />
                    </div>
                  </div> */}
                  <span className="title font-weight-bold text-white">Smartway Video Portal</span>
          </Link>
    
          <button className={`navbar-toggler bg-white order-1 ${mobileNav ? "" : 'collapsed'}`} onClick={onMobileNavHandler} type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded={mobileNav} aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
    
          <div className={`collapse navbar-collapse order-3 ${!!mobileNav ? 'show' : ""}`} id="navbarCollapse">
            {location.pathname.includes("/video-admin") && <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/video-admin/video-management" onClick={onMobileNavHandler} className={`nav-link  ${location.pathname === "/video-admin/video-management" && 'active'}`}>Video Management</Link>
              </li>
              <li className="nav-item">
                <Link to="/video-admin/change-password" onClick={onMobileNavHandler} className={`nav-link  ${location.pathname === "/video-admin/change-password" && 'active'}`}>Change Password</Link>
              </li>
            </ul>}
            <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">     
              <Dropdown className="user-avtar nav-item">
                <Dropdown.Toggle variant="link" className="nav-link p-0" style={{color:"white", fontWeight:700, fontFamily:"Dosis, sans-serif"}} id="dropdown-basic">
                  {!!location.pathname.includes("/video-admin") && isUserLogin?.log?.name ? isUserLogin?.log?.name : isUserLoginFront?.log?.name}
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-sm">
                  <button className="dropdown-item" style={{color:'rgba(33, 37, 41, .7)'}} onClick={onSignOutHandler}>
                    Sign out
                  </button>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </div>
        </nav>
    )
}

export default Navigation