import axios from "axios"
import { useCallback, useEffect, useRef, useState } from "react"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import ReactPaginate from 'react-paginate';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { userDeleteHandler, userEditHandler, userListHandler, userStatusChangeHandler } from "../../api";
import { getToken } from "../../global";
import moment from "moment/moment";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import AddUserEditUserModel from "./AddUserEditUserModel";
import { useNavigate } from "react-router-dom";

const Datatable = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [editUserDetails, setEditUserDetails] = useState("")
    const [entries, setEntries] = useState(5)
    const [currentPage, setCurretPage] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [sortBy, setSortBy] = useState({ key: "created_at", order: 'desc' });
    const [filterProductList, setFilterProductList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [show, setShow] = useState(false)
    const [selectedButtonLoading, setSelectedButtonLoading] = useState({
        id:"",
        loading:false,
        activeId:"",
        activeLoading:false,
        deleteId:"",
        deleteLoading:false
    })
    const navigate = useNavigate()

    const onAddVideoModelToogleHandler = useCallback(() => {
        setShow((prev) => {
            return !prev
        })
    }, [])

    useEffect(() => {
        if (!show) {
            setEditUserDetails("")
        }
    }, [show])

    const renderUserList = useCallback(async(currentPage, length, order_by, order_dir, search) => {
        setIsLoading(true)
        const token = getToken()
        try {
            const userListResponse = await userListHandler(token, currentPage, length, order_by, order_dir, search)
            
            if (userListResponse?.status === 200) {
                setFilterProductList(userListResponse?.data?.users?.data)
                setCurretPage(userListResponse?.data?.users?.current_page)
                setPageCount(userListResponse?.data?.users?.last_page)
                setIsLoading(false)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                navigate("/video-admin")
                setIsLoading(false)
            }
        } finally {
            setIsLoading(false)
        }
    }, [])

    useEffect(() => {
        renderUserList(1, entries, sortBy.key, sortBy.order, searchValue)
    }, [])

    const onEntriesChangeHandler = useCallback((e) => {
        setEntries(parseInt(e.target.value))
        setCurretPage(0)
        renderUserList(1,  parseInt(e.target.value), sortBy.key, sortBy.order, searchValue)
    }, [sortBy.key, sortBy.order, searchValue, renderUserList])

        const onSearchHandler = useCallback((e) => {
            const searchTerm = e.target.value.toLowerCase();
            setSearchValue(searchTerm)
            setTimeout(() => {
                renderUserList(1, entries, sortBy.key, sortBy.order, searchTerm)
            }, 1000)
        }, [entries, sortBy.key, sortBy.order]);

        const onSortHandler = useCallback((key) => {
            setSortBy(prevSortBy => ({
                key,
                order: prevSortBy?.key === key && prevSortBy.order === 'asc' ? 'desc' : 'asc'
            }));
            renderUserList(1, entries, key, sortBy?.key === key && sortBy?.order === 'asc' ? 'desc' : 'asc', searchValue)
        }, [entries, sortBy, searchValue]);

        const handlePageClick = useCallback((event) => {
            setCurretPage(event.selected + 1)
            renderUserList(event.selected + 1,  entries, sortBy.key, sortBy.order, searchValue)
        }, [entries, renderUserList, sortBy, searchValue])

        const onStatusChange = useCallback((id, status) => {
            Swal.fire({
                text: `Are you sure you want to ${status === 1 ? "Inactive this user" : status === 0 ? "Active this user" : ""}`,
                icon: 'warning',
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                cancelButtonColor:"#17a2b8",
                confirmButtonColor:"#17a2b8",
                showCancelButton:true
            }).then(async(result) => {
                if (!!result?.isConfirmed) {
                    // setIsLoading(true)
                    setSelectedButtonLoading((prev) => {
                        return {...prev, activeId:id, activeLoading:true}
                    })
                    const formData = new FormData()
                    formData.append("id", id)
                    formData.append("status", status === 1 ? 0 : 1)
                    const token = getToken()
                    try {
                        const statusResponse = await userStatusChangeHandler(token, formData)
                        if (statusResponse?.status === 200) {
                            toast.success(statusResponse?.data?.message)
                            setFilterProductList((prev) => {
                                const user = [...prev]
    
                                const index = user?.findIndex((items) => {
                                    return items?.id === id
                                })
    
                                user[index] = {
                                    ...user[index],
                                    status:user[index]?.status === 1 ? 0 : 1
                                }
    
                                return user
                            })
                            // setIsLoading(false)
                            setSelectedButtonLoading((prev) => {
                                return {...prev, activeId:id, activeLoading:false}
                            })
                        }
                    } catch (error) {
                        if (error?.response?.status === 401) {
                            setSelectedButtonLoading((prev) => {
                                return {...prev, activeId:id, activeLoading:false}
                            })
                            navigate("/video-admin")
                        }
                    } finally {
                        setSelectedButtonLoading((prev) => {
                            return {...prev, activeId:id, activeLoading:false}
                        })
                    }
                }
            })
        }, [])

        const onDeleteAndEditUserHandler = useCallback(async(id, name) => {
            const token = getToken()
            if (name === "delete") {
                Swal.fire({
                    text: `Are you sure you want to delete this user`,
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    cancelButtonColor:"#17a2b8",
                    confirmButtonColor:"#17a2b8",
                    showCancelButton:true,
                    
                }).then(async(result) => {
                    if (!!result?.isConfirmed) {
                        // setIsLoading(true)
                        setSelectedButtonLoading((prev) => {
                            return {...prev, deleteId:id, deleteLoading:true}
                        })
                        try {
                            const userDeleteResponse = await userDeleteHandler(token, id)
                            if (userDeleteResponse?.status === 200) {
                                setIsLoading(false)
                                toast.success("User deleted successfully")
                                // renderUserList(filterProductList?.length === 1 ? currentPage - 1 : currentPage,  entries, sortBy.key, sortBy.order, searchValue)
                                    const userListResponse = await userListHandler(token, filterProductList?.length === 1 ? currentPage - 1 : currentPage,  entries, sortBy.key, sortBy.order, searchValue)
                                    
                                    if (userListResponse?.status === 200) {
                                        setFilterProductList(userListResponse?.data?.users?.data)
                                        setCurretPage(userListResponse?.data?.users?.current_page)
                                        setPageCount(userListResponse?.data?.users?.last_page)
                                        setSelectedButtonLoading((prev) => {
                                            return {...prev, deleteId:id, deleteLoading:false}
                                        })
                                    }
                                
                                // setFilterProductList((prev) => {
                                //     const user = [...prev]

                                //     const filterUser = user?.filter((items) => {
                                //         return items?.id !== id
                                //     })

                                //     return filterUser
                                // })
                            }
                        } catch (error) {
                            if (error?.response?.status === 401) {
                                navigate("/video-admin")
                                setSelectedButtonLoading((prev) => {
                                    return {...prev, deleteId:id, deleteLoading:false}
                                })
                            }
                        } finally {
                            setSelectedButtonLoading((prev) => {
                                return {...prev, deleteId:id, deleteLoading:false}
                            })
                        }
                    }
                })
            } else if (name === "edit") {
                setSelectedButtonLoading((prev) => {
                    return {...prev, id, loading:true}
                })
                try {
                    const editResponse = await userEditHandler(token, id)
                    if (editResponse?.status === 200) {
                        setEditUserDetails(editResponse?.data?.user)
                        onAddVideoModelToogleHandler()
                        setSelectedButtonLoading((prev) => {
                            return {...prev, id, loading:false}
                        })
                    }
                } catch (error) {
                    if (error?.response?.status === 401) {
                        setSelectedButtonLoading((prev) => {
                            return {...prev, id, loading:false}
                        })
                        navigate("/video-admin")
                    }
                } finally {
                    setSelectedButtonLoading((prev) => {
                        return {...prev, id, loading:false}
                    })
                }
            }
        }, [currentPage, entries, sortBy, searchValue, filterProductList])

    return (
    <>  
    <div className="card-header main-title text-center text-lg-left">
        <h2 className="d-flex align-items-center" style={{gap:"11px"}}>Video Management  <button onClick={onAddVideoModelToogleHandler} className="rounded btn border bg-transparent"><i className="fa-solid fa-plus"></i></button></h2>
    </div>
    <div className="card-body">
        <div className="flex-column flex-md-row" style={{display:"flex", alignItems:"center", justifyContent:"space-between", marginBottom:"20px"}}>
            <label style={{fontWeight:400, display:"flex", alignItems:"center", gap:"2px"}}>  
                <span>Show </span>
                <select onChange={onEntriesChangeHandler} className="form-control" value={entries}>
                    <option onChange={() => {}} value={5}>5</option>
                    <option onChange={() => {}} value={10}>10</option>
                    <option onChange={() => {}} value={20}>20</option>
                </select>
                <span> entries</span>
            </label>
            <label style={{fontWeight:400, display:"flex", alignItems:"center", gap:"11px"}}>  
                <span>Search: </span>
                <input className="form-control" onChange={onSearchHandler}/>
            </label>
        </div>
        {isLoading ? <div className="text-center"><Spinner/></div> : !isLoading && !filterProductList.length ? <div className="text-center">No Data found</div> : <Table className="product-table">
                <Thead>
                    <Tr>
                        <Th><span className="d-flex align-items-center justify-content-between">Sr.No </span></Th>
                        <Th onClick={() => onSortHandler("video_sender_name")}><span className="d-flex align-items-center justify-content-between">Sender Name {sortBy.key === 'video_sender_name' && sortBy.order === "asc" ? <span className="arrow-sort">↑</span> : sortBy.key === 'video_sender_name' && sortBy.order === "desc" ? <span className="arrow-sort">↓</span> : <span className="arrow-sort">↓↑</span>}</span></Th>
                        <Th onClick={() => onSortHandler("video_title")}><span className="d-flex align-items-center justify-content-between">Video Title {sortBy.key === 'video_title' && sortBy.order === "asc" ? <span className="arrow-sort">↑</span> : sortBy.key === 'video_title' && sortBy.order === "desc" ? <span className="arrow-sort">↓</span> : <span className="arrow-sort">↓↑</span>}</span></Th>
                        <Th onClick={() => onSortHandler("created_at")}><span className="d-flex align-items-center justify-content-between">Created Date {sortBy.key === 'created_at' && sortBy.order === "asc" ? <span className="arrow-sort">↑</span> : sortBy.key === 'created_at' && sortBy.order === "desc" ? <span className="arrow-sort">↓</span> : <span className="arrow-sort">↓↑</span>}</span></Th>
                        <Th><span className="d-flex align-items-center justify-content-between">Status</span></Th>
                        <Th><span className="d-flex align-items-center justify-content-between">Action</span></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {filterProductList?.map((items, index) => {
                        return (
                            <Tr key={index}>
                                <Td>{items?.DT_RowIndex}</Td>
                                <Td>{items?.video_sender_name}</Td>
                                <Td>{items?.video_title}</Td>
                                <Td>{items?.created_at ? moment(items?.created_at).format("DD/MM/YYYY HH:mm:ss") : ""}</Td>
                                <Td>
                                    <div style={{display:"flex", alignItems:"center", gap:"5px"}}>
                                        <button onClick={() => onStatusChange(items?.id, items?.status)} className={`rounded btn border ${items?.status === 1 ? "bg-success" : items?.status === 0 ? "bg-danger" : ""}`}>{selectedButtonLoading?.activeId === items?.id && !!selectedButtonLoading?.activeLoading ? <Spinner size="sm"/> : <i className={`fa-solid ${items?.status === 1 ? 'fa-check' : items?.status === 0 ? 'fa-xmark' : ""} text-white`}></i>}</button>
                                    </div>
                                </Td>
                                <Td>
                                    <div style={{display:"flex", alignItems:"center", gap:"5px"}}>
                                        <button onClick={() => onDeleteAndEditUserHandler(items?.id, "edit")} className="rounded btn border bg-gray-light">{selectedButtonLoading?.id === items?.id && !!selectedButtonLoading?.loading ? <Spinner size="sm"/> : <i className="fas fa-solid fa-pencil-alt"></i>}</button>
                                        <button onClick={() => onDeleteAndEditUserHandler(items?.id, "delete")} className="rounded btn border bg-danger">{selectedButtonLoading?.deleteId === items?.id && !!selectedButtonLoading?.deleteLoading ? <Spinner size="sm"/> : <i className="far fa-trash-alt"></i>}</button>
                                    </div>
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
        </Table>}
        {filterProductList?.length ? <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", marginTop:"20px"}}>
            <label style={{fontWeight:400}}>  
                {/* <span>{`Showing ${filterArrIndex?.start + 1} to ${filterArrIndex?.end > filterProductList.length ? productList.length : filterArrIndex?.end} of ${filterProductList.length} ${searchValue ? `(filtered from ${productList?.length} total entries)` : 'entries'}`}</span>
                <span>{`Showing 1 to ${productList.slice(0, entries).length} of ${productList?.length} entries`}</span> */}
            </label>
            <ReactPaginate
                breakLabel="..."
                nextLabel="next"
                forcePage={currentPage - 1}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="previous"
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
            />
        </div> : ""}
        </div>
        {/* userList={renderUserList} page={1} entrie={entries} key={sortBy.key} order={sortBy.order} search={searchValue} */}
        <AddUserEditUserModel userList={renderUserList} page={currentPage} entrie={entries} keySort={sortBy?.key} order={sortBy.order} search={searchValue} editUser={editUserDetails} open={show} toogle={onAddVideoModelToogleHandler}/>
    </>
    )
}

export default Datatable