export const baseUrl = "https://video.smartwaypharma.com/api/"


export const getToken = () => {
    const isUserLogin = JSON.parse(localStorage.getItem("token_details"))
    return isUserLogin?.token
}

export const getTokenFront = () => {
    const isUserLogin = JSON.parse(localStorage.getItem("token_details_F"))
    return isUserLogin?.token
}