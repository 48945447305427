import axios from 'axios';
import { toast } from 'react-toastify';
import { baseUrl } from '../global/index';

const showToast = (message) => {
    return toast.error(message)
}

const axiosCustom = axios.create({
  baseURL: baseUrl, // Replace with your API base URL
  // baseURL:"http://192.168.10.244:5008/",
  headers: {
    'Content-Type': 'application/json' 
  }
});
    axiosCustom.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.errors?.password && error?.response?.data?.errors?.name) {
                    error?.response?.data?.errors?.password?.forEach((item) => {
                        showToast(item)
                    })
                    error?.response?.data?.errors?.name?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.password) {
                    error?.response?.data?.errors?.password?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.name) {
                    error?.response?.data?.errors?.name?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.video_name && error?.response?.data?.errors?.description && error?.response?.data?.errors?.password
                    && error?.response?.data?.errors?.name && error?.response?.data?.errors?.video_title && error?.response?.data?.errors?.video_sender_name
                ) {
                    error?.response?.data?.errors?.video_name?.forEach((item) => {
                        showToast(item)
                    })
                    error?.response?.data?.errors?.description?.forEach((item) => {
                        showToast(item)
                    })
                    error?.response?.data?.errors?.password?.forEach((item) => {
                        showToast(item)
                    })
                    error?.response?.data?.errors?.name?.forEach((item) => {
                        showToast(item)
                    })
                    error?.response?.data?.errors?.video_title?.forEach((item) => {
                        showToast(item)
                    })
                    error?.response?.data?.errors?.video_sender_name?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.video_name) {
                    error?.response?.data?.errors?.video_name?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.description) {
                    error?.response?.data?.errors?.description?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.password) {
                    error?.response?.data?.errors?.password?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.name) {
                    error?.response?.data?.errors?.name?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.video_sender_name) {
                    error?.response?.data?.errors?.video_name?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.video_title) {
                    error?.response?.data?.errors?.video_name?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.confirm_password) {
                    error?.response?.data?.errors?.confirm_password?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.new_password) {
                    error?.response?.data?.errors?.new_password?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.current_password) {
                    error?.response?.data?.errors?.current_password?.forEach((item) => {
                        showToast(item)
                    })
                } else if (error?.response?.data?.errors?.current_password && error?.response?.data?.errors?.new_password && error?.response?.data?.errors?.confirm_password) {
                    error?.response?.data?.errors?.current_password?.forEach((item) => {
                        showToast(item)
                    })
                    error?.response?.data?.errors?.new_password?.forEach((item) => {
                        showToast(item)
                    })
                    error?.response?.data?.errors?.confirm_password?.forEach((item) => {
                        showToast(item)
                    })
                }
            } else if (error?.response?.status === 401) {
                if (error?.response?.data?.message) {
                    showToast(error?.response?.data?.message)
                }

                if (!!window.location.pathname.includes("/video-admin")) {

                    localStorage.removeItem("token_details")
                } else if (!window.location.pathname.includes("/video-admin")) {
                    localStorage.removeItem("token_details_F")
                }
            } else if (error?.response?.status === 400) {
                if (error?.response?.data?.message) {
                    showToast(error?.response?.data?.message)
                }
            } else if (error?.response?.status === 500) {
                showToast("Something went wrong. Please try after sometime.")
            } else {
                showToast("Something went wrong. Please try after sometime.")
            }

            throw error;
        }
      );
export default axiosCustom;
