import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";

const MainLayout = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const isLoginUser = JSON.parse(localStorage.getItem("token_details"))
    const isLoginUserFront = JSON.parse(localStorage.getItem("token_details_F"))

    useEffect(() => {
        document.getElementById("htmlContent").className = "hold-transition"
        document.getElementById("htmlContent").className = "layout-top-nav"
        return () => {
            document.getElementById("htmlContent").className = "hold-transition"
            document.getElementById("htmlContent").className = "layout-top-nav"
        };
    }, []);

    useEffect(() => {
        if (!isLoginUser && !!location.pathname.includes("/video-admin")) {
            navigate("/video-admin")
            localStorage.removeItem("token_details")
        } else if (!isLoginUserFront && !location.pathname.includes("/video-admin")) {
            navigate("/")
            localStorage.removeItem("token_details_F")
        }
    }, [isLoginUser, isLoginUserFront])

    return (
        <div className="wrapper">
            <Navigation/>
                <div className="content-wrapper py-5">
                    <div className="content">
                        <div className="container">
                            <Outlet/>
                        </div>
                    </div>
                </div>
            <Footer/>
        </div>
    )   
}

export default MainLayout