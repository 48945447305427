import axiosCustom from "../axios"

export const loginCallHandler = async(userLoginDetails) => {
    try {

        const loginResponse = axiosCustom.post('admin/login', userLoginDetails, {
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        })
    
        return loginResponse
    } catch (err) {
        throw err
    }
}

export const UserAddHandler = async(userAddDetails, token) => {
    try {
        const userAddResponse = axiosCustom.post('admin/user/store', userAddDetails, {
            headers:{
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
    
        return userAddResponse
    } catch (err) {
        throw err
    }
}

export const changePasswordHandler = async(userAddDetails, token) => {
    try {
        const userAddResponse = axiosCustom.post('admin/changes-password', userAddDetails, {
            headers:{
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
    
        return userAddResponse
    } catch (err) {
        throw err
    }
}

export const userListHandler = async(token, currentPage, length, orderBy, order_dir, searchValue) => {
    try {

        const userAddResponse = axiosCustom.get(`admin/user?search=${searchValue}&order_by=${orderBy}&order_dir=${order_dir}&length=${length}&page=${currentPage}`, {
            headers:{
                'Authorization': `Bearer ${token}`
            }
        })
    
        return userAddResponse
    } catch (err) {
        throw err
    }
}

export const userStatusChangeHandler = async(token, payload) => {
    try {
        const userAddResponse = axiosCustom.post(`admin/user/changes-status`, payload, {
            headers:{
                'Authorization': `Bearer ${token}`
            }
        })
    
        return userAddResponse
    } catch (err) {
        throw err
    }
}

export const userDeleteHandler = async(token, id) => {
    try {

        const userAddResponse = axiosCustom.get(`admin/user/delete/${id}`, {
            headers:{
                'Authorization': `Bearer ${token}`
            }
        })
    
        return userAddResponse
    } catch (err) {
        throw err
    }
}

export const userEditHandler = async(token, id) => {
    try {

        const userAddResponse = axiosCustom.get(`admin/user/edit/${id}`, {
            headers:{
                'Authorization': `Bearer ${token}`
            }
        })
    
        return userAddResponse
    } catch (err) {
        throw err
    }
}

export const userUpdateHandler = async(token, payload, id) => {
    try {
        const userAddResponse = axiosCustom.post(`admin/user/update/${id}`, payload, {
            headers:{
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
    
        return userAddResponse
    } catch (err) {
        throw err
    }
}

export const LogOutHandler = async(token) => {
    try {
        const signUpResponse = axiosCustom.post(`/admin/logout`, null, {
            headers:{
                'Authorization': `Bearer ${token}`
            }
        })
    
        return signUpResponse
    } catch (err) {
        throw err
    }
}

// front-end

export const loginCallFrontHandler = async(userLoginDetails) => {
    try {
        const loginResponse = axiosCustom.post('user/login', userLoginDetails, {
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        })
    
        return loginResponse
    } catch (err) {
        throw err
    }
}

export const userDetailsHandler = async(token) => {
    try {
        const loginResponse = axiosCustom.get('user/dashboard', {
            headers:{
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    
        return loginResponse
    } catch (err) {
        throw err
    }
}

export const LogOutHandlerForFront = async(token) => {
    try {
        const signUpResponse = axiosCustom.post(`user/logout`, null, {
            headers:{
                'Authorization': `Bearer ${token}`
            }
        })
    
        return signUpResponse
    } catch (err) {
        throw err
    }
}



