import { useCallback, useEffect, useState } from "react"
import { Col, Modal, Row, Spinner } from "react-bootstrap"
// import { useForm } from "react-hook-form"
import { UserAddHandler, userUpdateHandler } from "../../api"
import { getToken } from "../../global"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const AddUserEditUserModel = (props) => {
    const [loading, setLoading] = useState(false)
    const [visiblePassword, setVisiblePassword] = useState("password")
    const [errors, setErr] = useState({
        status:"",
        password:"",
        userName:"",
        video:"",
        Title:"",
        Sender_Name:""
    })
    const [value, setValue] = useState({
        status:"",
        password:"",
        userName:"",
        video:null,
        Title:"",
        Sender_Name:"",
        description:""
    })
    const [selectedVideo, setSelectedVideo] = useState("")
    const {toogle, open, editUser, page, entrie, keySort, order, search, userList} = props
    // const {register, handleSubmit, formState: { errors }, setValue, reset, watch} = useForm()
    const navigate = useNavigate()


    // console.log("selected::::::::::::::::", selectedVideo)

    const onFieldChangeHandler = useCallback((value, name) => {
        if (name === "video" && value?.target?.files[0]) {
            // console.log("onChnage::::::::::::::", URL.createObjectURL(value?.target?.files[0]))
            setSelectedVideo(URL.createObjectURL(value?.target?.files[0]))
            setValue((prev) => {
                return {...prev, [name]:value?.target?.files[0] ? value?.target?.files[0] : null}
            })
            setErr((prev) => {
                return {...prev, video:""}
            })
        } else if (name !== "video") {
            if (!value.target.value.replace(/^\s*$/, '')) {
                setErr((prev) => {
                    return {...prev, [name]:value.target.value === "" ? `${name} is required` : ""}
                })
            } else if (name === "password") {
                setErr((prev) => {
                    return {...prev, [name]:value.target.value.length < 8 ? `Please enter 8 digits password.` : ""}
                })
            } else {
                setErr((prev) => {
                    return {...prev, [name]:value.target.value === "" ? `${name} is required` : ""}
                })
            }
            setValue((prev) => {
                return {...prev, [name]:value.target.value.replace(/^\s*$/, '')}
            })

        }
    }, [])


    useEffect(() => {
        setValue((prev) => {
            return {...prev, video:editUser?.video_name, description:editUser?.description, Sender_Name:editUser?.video_sender_name,
                status:editUser?.status === 1 ? "Active" : editUser?.status === 0 ? "Inactive" : "",
                Title:editUser?.video_title,
                userName:editUser?.name
            }
        })
        setSelectedVideo(`https://videoapp.smartwaypharma.com/static/upload/${editUser?.video_name}`)
        // setSelectedVideo(editUser?.video_name)
        // setValue("video", editUser?.video_name)
        // setValue("Sender_Name", editUser?.video_sender_name)
        // setValue("Title", editUser?.video_title)
        // setValue("userName", editUser?.name)
        // setValue("description", editUser?.description)
        // setValue("status", editUser?.status === 1 ? "Active" : editUser?.status === 0 ? "Inactive" : "")
    }, [editUser])


    const onAddEditUserHandler = useCallback(async(e) => {
        e.preventDefault()
        const data = value
        if (!data?.Sender_Name && !data?.Title && !data?.password && !data?.status && !data?.userName && !data?.video && !Object.keys(editUser)?.length) {
            setErr((prev) => {
                return {...prev, password: "Password is required", Sender_Name:"Sender_Name is required", status:"Status is required", Title:"Title is required", userName:"Username is required", video:"Video is required"}
            })
            setErr((prev) => {
                return {...prev, password:data?.password?.length < 8 ? `Please enter 8 digits password.` : ""}
            })
        } 

        if (!data?.Sender_Name) {
            setErr((prev) => {
                return {...prev, Sender_Name:"Sender_Name is required"}
            })
            
        } 
        
        if (!data?.Title) {
            setErr((prev) => {
                return {...prev, Title:"Title is required"}
            })
            
        } 
        
        if (!data?.password && !Object.keys(editUser)?.length) {
                setErr((prev) => {
                    return {...prev, password: "Password is required"}
                })
        }
        
        if (!Object.keys(editUser)?.length && data?.password?.length < 8) {
                setErr((prev) => {
                    return {...prev, password: "Please enter 8 digits password."}
                })
        } 
        
        if (!data?.status) {
            setErr((prev) => {
                return {...prev, status: "Status is required"}
            })
            
        } 
        
        if (!data?.userName) {
            setErr((prev) => {
                return {...prev, userName: "Username is required"}
            })
            
        } 

        if (!data?.video && typeof data?.video !== "string") {
            setErr((prev) => {
                return {...prev, video: "Video is required"}
            })
        } 

        setLoading(true)
        const formData = new FormData()
        formData.append("video_sender_name", data?.Sender_Name)
        formData.append("video_title", data?.Title)
        formData.append("name", data?.userName)
        formData.append("password", data?.password)
        formData.append("description", data?.description === "null" && !data?.description ? "" : data?.description)
        formData.append("status", data?.status === "Active" ? 1 : 0)
        const token = getToken()
        
        try {
                if (editUser) {
                    
                    if (data?.Sender_Name && data?.Title && data?.status && data?.userName && data?.video) {
                        formData.append("video_name", typeof data?.video === "string" ? "" : data?.video)
                        const updateUserResponse = await userUpdateHandler(token, formData, editUser?.id)
                        if (updateUserResponse?.status === 200) {
                            toast.success("User Updated successfully")
                            toogle()
                            setLoading(false)
                            userList(page, entrie, keySort, order, search)
                        }
                    }
                    
                } else {
                    if (data?.Sender_Name && data?.Title && data?.password && data?.status && data?.userName && data?.video && data.password.length >= 8) {
                        formData.append("video_name", data?.video)
                        const userAddResponse = await UserAddHandler(formData, token)
                        
                        if (userAddResponse?.status === 200) {
                            toast.success("User added successfully")
                            toogle()
                            setLoading(false)
                            userList(1, entrie, keySort, order, search)
                        }
                    }
                }
    
            } catch (error) {
                if (error?.response?.status === 401) {
                    navigate("/video-admin")
                    setLoading(false)
                }
            } finally {
                    setLoading(false)
            }

    }, [editUser, toogle, page, entrie, keySort, order, search, userList, value])

    useEffect(() => {
        if(open === false) {
            setSelectedVideo("")
            // reset()
            setValue({
                status:"",
                password:"",
                userName:"",
                video:null,
                Title:"",
                Sender_Name:"",
                description:""
            })

            setErr({
                status:"",
                password:"",
                userName:"",
                video:"",
                Title:"",
                Sender_Name:""
            })
        }
    }, [toogle, open])

    const onVisiblePasswordHandler = useCallback(() => {
        setVisiblePassword((prev) => {
            if (prev === "password") {
                return "text"
            } else if (prev === "text") {
                return "password"
            }
        })
    }, [])


    return (
        <Modal show={open} size="xl" onHide={toogle}>
                    <Modal.Title className="p-3 main-title text-center border-bottom m-0 row">
                        <div className="col-4 d-none d-sm-block"></div>
                        <div className="col-11 col-sm-4">
                            {!!editUser ? "Edit Video" : "Add Video"} 
                        </div>
                        <div className="col-1 col-sm-4 d-flex align-items-center justify-content-end">
                            <i onClick={() => toogle()} className="fa-solid fa-xmark"></i>
                        </div>
                    </Modal.Title>
                    <Modal.Body>
                        <form onSubmit={onAddEditUserHandler}>
                            <Row>
                                {selectedVideo && <Col sm="6">
                                    <Row>
                                        {!!selectedVideo && <Col sm="12">
                                                <label className="font-weight-normal">Choose Video: </label>
                                                <video loop autoPlay controls className="w-100 rounded" src={selectedVideo}/>
                                        </Col>}
                                    </Row>
                                </Col>}
                                <Col sm={selectedVideo ? "6" : "12"}>
                                    <Row>
                                    <Col lg={selectedVideo ? "6" : "4"}>
                                        {!Object.keys(editUser).length ? <div className="form-group">
                                            <label className="font-weight-normal">Username: <span className="text-danger">*</span></label>
                                            <input type="text" value={value?.userName} onChange={(e) => onFieldChangeHandler(e, "userName")} className="form-control"  placeholder="Enter Username"/>
                                        </div> :
                                        <div className="form-group">
                                            <label className="font-weight-normal">Username: <span className="text-danger">*</span></label>
                                            <label className="form-control bg-gray-light font-weight-normal">{value?.userName}</label>
                                        </div>
                                        }
                                        {errors?.userName && <p className="text-danger">{errors?.userName}</p>}
                                    </Col>
                                    <Col lg={selectedVideo ? "6" : "4"}>
                                        <div className="form-group position-relative">
                                            <label className="font-weight-normal">Password: {!!editUser ? "" : <span className="text-danger">*</span>}</label>
                                            <input type={visiblePassword} value={value?.password} onChange={(e) => onFieldChangeHandler(e, "password")} className="form-control"  placeholder="Enter Password"/>
                                            <i onClick={onVisiblePasswordHandler} style={{top:"38px", right:"15px"}} className={`fa-solid position-absolute ${visiblePassword === "password" ? "fa-eye" :visiblePassword === "text" ?  "fa-eye-slash" : ""}`}></i>
                                        </div>
                                        {errors?.password && <p className="text-danger">{errors?.password}</p>}
                                    </Col>
                                    <Col lg={selectedVideo ? "6" : "4"}>
                                        <div className="form-group">
                                            <label className="font-weight-normal">Sender Name: <span className="text-danger">*</span></label>
                                            <input type="text" value={value?.Sender_Name} onChange={(e) => onFieldChangeHandler(e, "Sender_Name")} className="form-control" placeholder="Enter Sender Name"/>
                                        </div>
                                        {errors?.Sender_Name && <p className="text-danger">{errors?.Sender_Name}</p>}
                                    </Col>
                                    <Col lg={selectedVideo ? "6" : "4"}>
                                       
                                        <div className="form-group">
                                            <label className="font-weight-normal">Title: <span className="text-danger">*</span></label>
                                            <input type="text" value={value?.Title} onChange={(e) => onFieldChangeHandler(e, "Title")} className="form-control" placeholder="Enter Title"/>
                                        </div>
                                        {errors?.Title && <p className="text-danger">{errors?.Title}</p>}
                                    </Col>
                                    <Col lg={selectedVideo ? "6" : "4"}>
                                        <div className="form-group">
                                            <label className="font-weight-normal" htmlFor="exampleInputFile">Upload Video: <span className="text-danger">*</span></label>
                                            <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" onChange={(e) => onFieldChangeHandler(e, "video")} accept="video/mp4, video/avi, video/mkv, video/quicktime" 
                                                id="exampleInputFile"/>
                                                <label className="custom-file-label"  htmlFor="exampleInputFile">Choose Video</label>
                                            </div>
                                            {/* <div className="input-group-append">
                                                <span className="input-group-text">Upload</span>
                                            </div> */}
                                            </div>
                                        </div>
                                        {value?.video && <p>{value?.video?.name}</p>}
                                        {errors?.video && <p className="text-danger">{errors?.video}</p>}
                                    </Col>
                                   
                                    <Col lg={selectedVideo ? "6" : "4"}>
                                        <div className="form-group">
                                            <label className="font-weight-normal">Status: <span className="text-danger">*</span></label>
                                            <select className="form-control" value={value?.status} onChange={(e) => onFieldChangeHandler(e, "status")}>
                                                <option value="">Select Status</option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                        </div>
                                        {errors?.status && <p className="text-danger">{errors?.status}</p>}
                                    </Col>
                                    <Col lg={selectedVideo ? "6" : "4"}>
                                        <div className="form-group">
                                            <label className="font-weight-normal">Description:</label>
                                            <textarea className="form-control" value={value?.description !== "null" && !!value.description ? value?.description : ""} onChange={(e) => onFieldChangeHandler(e, "description")} placeholder="Enter Description"/>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <button type="submit" className="btn btn-info">{loading ? <Spinner/> : "Save"}</button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </form>
                    </Modal.Body>
                </Modal>
    )
}

export default AddUserEditUserModel